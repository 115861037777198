import React from 'react';
import DashboardWrapper from '../../containers/Dashboard/DashboardWrapper';
import SettingsWrapper from '../../containers/Dashboard/SettingsWrapper/SettingsWrapper';

const DashboardHome = ({ location, pageContext }) => {
  return (
    <DashboardWrapper location={location} pageContext={pageContext}>
      <SettingsWrapper />
    </DashboardWrapper>
  );
};

export default DashboardHome;
