

const does_user_exist = ({ user_wrap_obj }) => {

    if(user_wrap_obj && user_wrap_obj.user) {
       return true
    }
 
    else {
       return false;
    }
}

export default does_user_exist;