import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import styles from './DashboardSideBar.module.css';
// import { getSelectedIndex } from './utils';
import { LangContext } from '../../context/LangContext';

const DashboardSideBar = ({ location, sidebarOpen, toggleSidebar }) => {
  const { translations } = useContext(LangContext);

  const sidebarClasses = sidebarOpen
    ? [styles['sidebar'], styles['mobileOpen']].join(' ')
    : styles['sidebar'];

  const items = translations.general.dashboardNavItems;

  return (
    <>
      {sidebarOpen && (
        <div className={styles['backdrop']} onClick={toggleSidebar} />
      )}
      <div className={sidebarClasses}>
        <Link to="/">
          <h2 className={styles['logo']}>
            <span /*className={styles["logoBorderEffect"]}*/ />
            <span>Eddisrupt </span>
          </h2>
        </Link>
        <ul className={styles['list']}>
          {items.map((item) => {
            return (
              <div className={styles.sideBar__item}>
                <li
                  onClick={() => {
                    toggleSidebar();
                  }}
                  className={
                    location.pathname === item.path
                      ? [styles['link'], styles['selected']].join(' ')
                      : styles['link']
                  }
                >
                  {item.external ? (
                    <a
                      className={styles.anchor}
                      href={item.path}
                      target="_blank"
                    >
                      {item.name}
                    </a>
                  ) : (
                    <Link to={item.path} className={styles.anchor}>
                      {item.name}
                    </Link>
                  )}
                </li>
                {item.icon}
              </div>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default DashboardSideBar;
