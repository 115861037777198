
import { navigate } from 'gatsby';
import logout from './logout';

const on_logout_click_dash = ({ setUserGlobalState }) => {

    navigate('/login');

    // Refactor this setTimeout <=
    setTimeout(() => {
        logout({ setUserGlobalState });
    }, 2000);

}

export default on_logout_click_dash;