import React, { useContext, useEffect, useRef } from 'react';
import DashboardNavbar from '../DashboardNavbar/DashboardNavbar';
import DashboardSideBar from '../DashboardSideBar/DashboardSideBar';
import useSetState from '../../../utils/useSetState';
import { UserContext } from '../../UserContext/UserContext';
import LangProvider from '../../context/LangContext';
import validate_session from '../../../utils/validate_session';
import styles from './DashboardLayout.module.css';
import does_user_exist from '../../../utils/does_user_exist';
import { Feedback } from '../../../components';
import { Helmet } from 'react-helmet';

const DashboardWrapper = ({ children, location, noLayout, pageContext }) => {
  const { translations, lang } = pageContext;

  const [state, setState] = useSetState({
    feedback: {
      isActive: false,
      message: '',
      goto_route_prompt: ''
    },
    sidebarOpen: false
  });

  const { userGlobalState, setUserGlobalState } = useContext(UserContext);

  useEffect(() => {
    // remove drift chat

    removeSupportChat();

    // analyse session

    if (!does_user_exist({ user_wrap_obj: userGlobalState })) {
      validate_session().then((sessions_obj) => {
        if (sessions_obj.error) {
          setState({
            feedback: {
              isActive: true,
              message: 'Your session expired, please log in',
              goto_route_prompt: '/login'
            }
          });
        } else {
          if (!does_user_exist({ user_wrap_obj: sessions_obj })) {
            setState({
              feedback: {
                isActive: true,
                message: 'Your session expired, please log in',
                goto_route_prompt: '/login'
              }
            });
          }

          setUserGlobalState({ ...sessions_obj });
        }
      });
    }
  }, []);

  const wrapper = useRef(null);

  const toggleSidebar = () => {
    const { sidebarOpen } = state;
    setState({ sidebarOpen: !sidebarOpen });
  };

  return (
    <LangProvider translations={translations} lang={lang}>
      <div>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        {userGlobalState && !state.feedback.isActive ? (
          noLayout ? (
            children
          ) : (
            <div>
              <div className={styles['contentWrapper']} ref={wrapper}>
                <DashboardSideBar
                  location={location}
                  sidebarOpen={state.sidebarOpen}
                  toggleSidebar={toggleSidebar}
                />
                <div className={styles['contentPage']}>
                  <DashboardNavbar toggleSidebar={toggleSidebar} />
                  <div className={styles['children']}>{children}</div>
                </div>
              </div>
            </div>
          )
        ) : null}
        {state.feedback.isActive || state.feedback.isLoading ? (
          <Feedback
            message={state.feedback.message}
            button_url={state.feedback.goto_route_prompt}
            button_text="Redirect"
            isLoading={state.feedback.isLoading}
          />
        ) : null}
      </div>
    </LangProvider>
  );
};

export default DashboardWrapper;

const removeSupportChat = () => {
  try {
    document.querySelector('#drift-frame-chat').style['display'] = 'none';
    document.querySelector('#drift-frame-controller').style['display'] = 'none';
  } catch (err) {
    // MUST HAVE TRY CATCH, CRASHES IN DEV WITHOUT IT
  }
};
