import axios from 'axios';
import { api_base_url } from '../../../../data/client';
import is_email_valid from '../../../../utils/is_email_valid';

const email_mismatch_message = "The new emails provided don't match";
const no_email_message = 'Please provide a new email';
const no_password_message = 'Please provide your password';

const onSubmitEmailHandler = async ({
  setState,
  state,
  userGlobalState,
  setFeedbackState
}) => {
  // Validation

  if (!state.new_email) {
    setFeedbackState({
      isActive: true,
      message: no_email_message,
      isError: true,
      isLoading: false
    });
  } else if (!is_email_valid(state.new_email)) {
    setFeedbackState({
      isActive: true,
      message: 'The provided email is not valid',
      isError: true,
      isLoading: false
    });
  } else if (state.confirm_new_email != state.new_email) {
    setFeedbackState({
      isActive: true,
      message: email_mismatch_message,
      isError: true,
      isLoading: false
    });
  } else if (!state.password_emailForm) {
    setFeedbackState({
      isActive: true,
      message: no_password_message,
      isError: true,
      isLoading: false
    });
  } else {
    setFeedbackState({
      isActive: true,
      isError: false,
      isLoading: true
    });

    const res = await axios({
      url: `${api_base_url}/users/${userGlobalState.user._id}/change_email`,
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json; charset=utf-8'
      },
      data: JSON.stringify(state)
    });

    if (!res.data.error) {
      setFeedbackState({
        isActive: true,
        message: 'Saved',
        isError: false,
        isLoading: false
      });
    } else {
      setFeedbackState({
        isActive: true,
        message: res.data.message,
        isError: true,
        isLoading: false
      });
    }
  }
};

export default onSubmitEmailHandler;
