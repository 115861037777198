import React, { useContext } from 'react';
import { UserContext } from '../../UserContext/UserContext';
import styles from './DashboardNavbar.module.css';
import { Avatar, Popper } from '../../../components';
import { Link } from 'gatsby';
import { NotificationsNone, Menu, KeyboardArrowDown } from '@material-ui/icons';
import on_logout_click_dash from '../../../utils/on_logout_click_dash';

const DashboardNavbar = ({ toggleSidebar }) => {
  const { userGlobalState, setUserGlobalState } = useContext(UserContext);

  return (
    <div className={styles['container']}>
      <div className={styles.navbar__optionsSection}>
        {/* Mobile Menu */}
        <div className={styles.navbar__icon + ' ' + styles.mobileMenuIconWrap}>
          <Menu onClick={toggleSidebar} fontSize="inherit" color="inherit" />
        </div>

        <div className={styles.navbar__icon}>
          <Popper
            top="41px"
            height="120px"
            TriggerComponent={
              <NotificationsNone fontSize="inherit" color="inherit" />
            }
          >
            <div className={styles.notificationsText}>
              You have no notifications yet
            </div>
          </Popper>
        </div>
        <div className={styles.avatar__container}>
          <Popper
            width="140px"
            TriggerComponent={
              <div className={styles.avatarBox}>
                <Avatar
                  label={
                    userGlobalState.user.first_name[0].toUpperCase() +
                    userGlobalState.user.last_name[0].toUpperCase()
                  }
                />
                <KeyboardArrowDown color="inherit" />
              </div>
            }
          >
            <div className={styles.navPopper__contentBox}>
              <div>
                <Link to="/dashboard/settings">
                  <div className={styles.navPopper__itemText}>Account</div> 🔨
                </Link>
              </div>

              {/* <div>
                <Link to="/dashboard/payments">
                  <div className={styles.navPopper__itemText}>Billing</div> 💵
                </Link>
              </div> */}

              <div
                onClick={on_logout_click_dash.bind(null, {
                  userGlobalState,
                  setUserGlobalState
                })}
              >
                <div className={styles.navPopper__itemText}>Log out</div> 🏃‍♀️
              </div>
            </div>
          </Popper>
        </div>
      </div>
    </div>
  );
};

export default DashboardNavbar;
