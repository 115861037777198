import axios from 'axios';
import { api_base_url } from '../../../../data/client';

const password_mismatch_message = "The new passwords provided don't match";
const no_current_password_message = 'Please provide your current password';
const no_new_password_message = 'Please provide a new password';

const onSubmitPasswordHandler = async ({
  setState,
  state,
  userGlobalState,
  setFeedbackState
}) => {
  // Validation

  if (!state.new_password) {
    setFeedbackState({
      isActive: true,
      message: no_new_password_message,
      isError: true,
      isLoading: false
    });
  } else if (state.confirm_new_password != state.new_password) {
    setFeedbackState({
      isActive: true,
      message: password_mismatch_message,
      isError: true,
      isLoading: false
    });
  } else if (!state.password_passwordForm) {
    setFeedbackState({
      isActive: true,
      message: no_current_password_message,
      isError: true,
      isLoading: false
    });
  } else {
    setFeedbackState({
      isActive: true,
      isError: false,
      isLoading: true
    });

    const res = await axios({
      url: `${api_base_url}/users/${userGlobalState.user._id}/change_password`,
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json; charset=utf-8'
      },
      data: JSON.stringify(state)
    });

    if (!res.data.error) {
      setFeedbackState({
        isActive: true,
        message: 'Saved',
        isError: false,
        isLoading: false
      });
    } else {
      setFeedbackState({
        isActive: true,
        message: res.data.message,
        isError: true,
        isLoading: false
      });
    }
  }
};

export default onSubmitPasswordHandler;
