import axios from 'axios';
import { api_base_url } from '../data/client';

const validate_session = async () => {
  try {
    const token = localStorage.getItem('s_t_kmp');

    if (token) {
      const res = await axios({
        url: api_base_url + '/users/auth/validate_session',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      if (res && res.data && !res.data.error) {
        return res.data;
      } else {
        return { error: true };
      }
    } else {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }
};

export default validate_session;
