import React, { useContext } from 'react';
import styles from './Settings.module.css';
import { Input, YhellowButton } from '../../../components';
import { LangContext } from '../../context/LangContext';

const Settings = ({
  state,
  setState,
  onSubmitPasswordHandler,
  onSubmitEmailHandler
}) => {
  const { translations } = useContext(LangContext);

  return (
    <>
      <div
        style={{ marginBottom: '50px' }}
        className={styles['settings__wrapper']}
      >
        <h1 className={styles.title + ' mainTitle'}>
          {translations.emailATitle}
        </h1>
        <div className={styles.inputField}>
          <div className={styles.inputTitle}>
            {translations.emailANewEmailPlace}
          </div>
          <Input
            value={state.email}
            onChange={(e) => {
              setState({ new_email: e.target.value });
            }}
            name="email"
            label={translations.emailANewEmailPlace}
          />
        </div>
        <div className={styles.inputField}>
          <div className={styles.inputTitle}>
            {translations.emailANewEmailPlaceConfirm}
          </div>
          <Input
            value={state.confirm_new_email}
            onChange={(e) => {
              setState({ confirm_new_email: e.target.value });
            }}
            name="confirm_email"
            label={translations.emailANewEmailPlaceConfirm}
          />
        </div>
        <div className={styles.inputField}>
          <div className={styles.inputTitle}>
            {translations.emailAPassPlace}
          </div>
          <Input
            value={state.password_emailForm}
            onChange={(e) => {
              setState({ password_emailForm: e.target.value });
            }}
            name="password"
            type="password"
            label={translations.emailAPassPlace}
          />
        </div>
        <div className={styles['submit__section']}>
          <div className={styles['save__wrapper']}>
            <YhellowButton
              label="Save"
              onClick={onSubmitEmailHandler}
              size="small"
            >
              {translations.emailABtnText}
            </YhellowButton>
          </div>
        </div>
      </div>

      <div className={styles['settings__wrapper']}>
        <h1 className={styles.title + ' mainTitle'}>
          {translations.passwordATitle}
        </h1>
        <div className={styles.inputField}>
          <div className={styles.inputTitle}>
            {translations.passwordANewPassPlace}
          </div>
          <Input
            value={state.new_password}
            onChange={(e) => {
              setState({ new_password: e.target.value });
            }}
            name="new_password"
            type="password"
            label={translations.passwordANewPassPlace}
          />
        </div>
        <div className={styles.inputField}>
          <div className={styles.inputTitle}>
            {translations.passwordANewPassPlaceConfirm}
          </div>
          <Input
            value={state.confirm_new_password}
            onChange={(e) => {
              setState({ confirm_new_password: e.target.value });
            }}
            name="confirm_new_password"
            type="password"
            label={translations.passwordANewPassPlaceConfirm}
          />
        </div>
        <div className={styles.inputField}>
          <div className={styles.inputTitle}>
            {translations.passwordAPassPlace}
          </div>
          <Input
            value={state.password_passwordForm}
            onChange={(e) => {
              setState({ password_passwordForm: e.target.value });
            }}
            name="password"
            type="password"
            label={translations.passwordAPassPlace}
          />
        </div>
        <div className={styles['submit__section']}>
          <div className={styles['save__wrapper']}>
            <YhellowButton
              style={{ maxWidth: '100%' }}
              onClick={onSubmitPasswordHandler}
              size="small"
            >
              {translations.passwordABtnText}
            </YhellowButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
