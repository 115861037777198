import React, { useContext } from 'react';
import { UserContext } from '../../UserContext/UserContext';
import Settings from '../Settings/Settings';
import useSetState from '../../../utils/useSetState';
import onSubmitEmailHandler from './utils/onSubmitEmailHandler';
import onSubmitPasswordHandler from './utils/onSubmitPasswordHandler';
import { FeedbackContext } from '../../context/FeedbackContext/FeedbackContext';

const SettingsWrapper = () => {
  const { userGlobalState } = useContext(UserContext);
  const { setFeedbackState } = useContext(FeedbackContext);

  const [state, setState] = useSetState({
    new_email: '',
    confirm_new_email: '',
    password_emailForm: '',
    password_passwordForm: '',
    new_password: '',
    confirm_new_password: '',
    isLoading: true
  });

  return (
    <Settings
      state={state}
      setState={setState}
      onSubmitPasswordHandler={onSubmitPasswordHandler.bind(null, {
        state,
        setState,
        userGlobalState,
        setFeedbackState
      })}
      onSubmitEmailHandler={onSubmitEmailHandler.bind(null, {
        state,
        setState,
        userGlobalState,
        setFeedbackState
      })}
    />
  );
};

export default SettingsWrapper;
