
const logout = ({ setUserGlobalState }) => {

    localStorage.removeItem('s_t_kmp');

    setUserGlobalState({
        token: null,
        user: null
    })

}

export default logout;

